









































import Vue from 'vue'
import Component from 'vue-class-component'

import VictualSearchCategorySelect from './VictualSearchCategorySelect.vue'

import { VictualSearch } from './victualSearch'
import { PropSync, Emit, Watch } from 'vue-property-decorator'

@Component({ components: { VictualSearchCategorySelect } })
export default class VictualSearchForm extends Vue {
  private showSearchOptions = false

  @PropSync('value')
  search!: VictualSearch

  @Emit('value')
  emitValue (): VictualSearch {
    return this.search
  }

  @Watch('search', { deep: true })
  valueChanged (): void {
    if (this.search.categories.length > 0) {
      this.showSearchOptions = true
    }
    this.emitValue()
  }

  get isDetailsClosable (): boolean {
    return this.search.categories.length === 0
  }
}
