





















import Vue from 'vue'
import Component from 'vue-class-component'

import { VictualSearch } from './victualSearch'
import { FIREBASE_ARRAY_OPERATION_MAX_PARAMETERS } from '../api/firebaseApi'
import { PropSync, Emit, Watch } from 'vue-property-decorator'
import { VictualCategory } from '../model/victual'

@Component({})
export default class VictualSearchCategorySelect extends Vue {
  @PropSync('value')
  searchOptions!: VictualSearch

  @Emit('value')
  emitValue (): VictualSearch {
    return this.searchOptions
  }

  @Watch('searchOptions', { deep: true })
  valueChanged (): void {
    this.emitValue()
  }

  get availableCategories (): Array<{ text: string, value: VictualCategory }> {
    return Object.values(VictualCategory).map(category => {
      return {
        text: this.$t('vic.cat.' + category) as string,
        value: category
      }
    })
  }

  get maxSelectableItemsString (): string {
    return `${this.$root.$t('vic.categories') as string} (max. 10)`
  }

  limitToMaxSelections (e: VictualCategory[]): void {
    if (e.length > FIREBASE_ARRAY_OPERATION_MAX_PARAMETERS) {
      e.pop()
    }
  }
}
